import {createApp} from 'vue'
import {createPinia} from 'pinia'

import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';


import '@/assets/styles.scss';
import '@/assets/tailwind.css';

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.use(PrimeVue,
    {
        ripple: true,
        theme: {
            preset: Aura,
            options: {
                darkModeSelector: '.app-dark',
            }
        }
    }
);

app.use(ToastService);
app.use(ConfirmationService);
app.use(DialogService);


app.mount('#app')
