<script setup lang="ts">
import {ref} from 'vue';


const appVersion = ref("");

import {APP_VERSION, APP_VERSION_DEV, BUILD_DATE, BUILD_NUMBER} from "@/version";

if (import.meta.env.MODE == "development" || import.meta.env.MODE == "test") {
  appVersion.value = "v" + APP_VERSION_DEV + " - " + BUILD_DATE;
} else {
  appVersion.value = "v" + APP_VERSION + '-' + BUILD_NUMBER;
}

</script>

<template>
    <div class="layout-footer">
      <img :src="'layout/images/logo.png'" alt="uCase"  class="ml-5 h-[20px]"/>
      {{ appVersion }}
      by
      <span class="font-medium ml-2">atdev</span>
    </div>
</template>
