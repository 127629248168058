import {type Ref, ref} from "vue";
import {defineStore} from "pinia";

import AuthService from "@/services/auth.service";
import type {LoginCommand} from "@/commands/auth.commands";
import {EStatusUsuario} from "@/type/usuario.type";


const userStorage = localStorage.getItem('user');
const userNameStorage = localStorage.getItem('userName');
const userEmailStorage = localStorage.getItem("userEmail");
const userStatusStorage = localStorage.getItem("userStatus");
const userImageStore = localStorage.getItem('userImage');
const userPublicIdStorage = localStorage.getItem('userPublicId');


export const useAuthStore = defineStore('authStore', () => {


    const user = ref(userStorage);
    const userName = ref(userNameStorage);
    const userEmail = ref(userEmailStorage);
    const userStatus: Ref<string | EStatusUsuario | null> = ref(userStatusStorage);
    const userImage = ref(userImageStore);
    const userPublicId = ref(userPublicIdStorage);

    const isValidSession = ref();


    function isLoggedIn() {
        return !!localStorage.getItem('user');
    }

    function entrar(user: LoginCommand) {

        return AuthService
            .login(user)
            .then((userRet: any) => {

                userName.value = userRet.nome;
                userEmail.value = userRet.email;
                userStatus.value = userRet.statusUsuario;
                userImage.value = userRet.imagem;
                userPublicId.value = userRet.publicId;
                isValidSession.value = true;

                return Promise.resolve(userRet);

            })
            .catch((error) => {
                return Promise.reject(error);
            });


    }

    function sair() {
        AuthService.logout();
        isValidSession.value = null;
    }

    function setUserName(apelido: string) {
        userName.value = apelido;
        localStorage.setItem('userName', apelido);
    }

    function setUserStatus(statusUsuario: EStatusUsuario) {
        userStatus.value = statusUsuario;
        localStorage.setItem('userStatus', statusUsuario.toString());
    }

    function setUserEmail(email: string) {
        userEmail.value = email;
        localStorage.setItem('userEmail', email);
    }

    async function verificaSessaoUsuario() {

        try {
            if (isValidSession.value != true) {

                const ret =  await AuthService.validateSession();

                isValidSession.value = (ret === true);

            }
            return Promise.resolve(true);
        } catch (e) {
            localStorage.removeItem('user');
            return Promise.resolve(false);
        }
    }


    return {
        user,
        userName,
        userEmail,
        userStatus,
        userImage,
        isLoggedIn,
        userPublicId,
        entrar,
        sair,
        setUserName,
        setUserStatus,
        setUserEmail,
        verificaSessaoUsuario,
    }
});



