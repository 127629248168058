import {useRequestHelper} from "@/helpers/request.helper";
import type {UsuarioInterno} from "@/type/usuario.type";
import type {
    AlterarEmailUsuarioCommand,
    AlterarInfoUsuarioCommand,
    AlterarSenhaUsuarioCommand,
    CadastrarUsuarioCommand,
    ConfirmarEmailCommand,
    RecuperarSenhaUsaurioCommand,
    SolicitarRecuperacaoUsuarioCommand
} from "@/commands/usuario.commands";

const requestHelper = useRequestHelper();


class UsuarioService {

    cadasrarUsuario(command: CadastrarUsuarioCommand) {
        return requestHelper.sendRequestPost<any>("usuario/cadastrar", command)
    }

    consultar() {
        return requestHelper.sendRequestGet<UsuarioInterno>('usuario/interno/info');
    }

    alterarInfoUsuario(command: AlterarInfoUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/informacoes', command);
    }

    alterarSenha(command: AlterarSenhaUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/senha', command);
    }


    alterarEmail(command: AlterarEmailUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/email', command);
    }


    solicitarCodigoConfirmacao() {
        return requestHelper.sendRequestPost<object>('usuario/solicitar/confirmacao', {});
    }

    confirmarEmail(command: ConfirmarEmailCommand) {
        return requestHelper.sendRequestPost<object>('usuario/confirmar', command);
    }

    solicitarRecuperacaoSenha(command: SolicitarRecuperacaoUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/solicitar/recuperacao', command, false);

    }

    recuperarSenha(command: RecuperarSenhaUsaurioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/recuperar-senha', command, false);
    }


}

export default new UsuarioService();