import {createRouter, createWebHashHistory} from 'vue-router';


import {useAuthStore} from "@/stores/auth.store";
import AppLayout from "@/layout/AppLayout.vue";
import {useHomeStore} from "@/stores/home.store";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/signin",
            name: "signin",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageSignin.vue')
        },
        {
            path: "/usuario/recuperacao-senha/:solicitacaoId",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageRecuperacaoSenha.vue')
        },
        {
            path: "/usuario/cadastro",
            name: "usuario_cadastro",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageUsuarioCadastro.vue')
        },
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/home/PageHome.vue')
                },
                {
                    path: '/perfil',
                    name: 'perfil',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/perfil/PagePerfil.vue')
                },

                {
                    path: '/lancamentos',
                    name: 'lancamentos',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/lancamento/PageLancamento.vue')
                },


            ]
        },


    ]
});

router.beforeEach(async (to, from, next) => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = true;

    const authStore = useAuthStore();
    const authRequired = (!to.meta ? true : to.meta.authRequired);
    const loggedIn = authStore.isLoggedIn();

    //Verifica se é necessário autenticação para acessar a rota/pagina
    //Verifica se o usuário está logado
    if (authRequired && !loggedIn) {


        //Caso necessita de autenticacao e o perfil nao estiver logado, redireciona para pagina de signin

        next('/signin');

    } else {

        //Está logado


         //Verifica a sessao
        if(authRequired){
            if(await authStore.verificaSessaoUsuario()){
                next();
            }else{
                //Se deu erro na validacao da sessao, retorna para o login
                next('/signin');
            }
        }else{
            next();
        }





    }
});

router.afterEach(() => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = false;
})


export default router;
