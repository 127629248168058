import {useRequestHelper} from "@/helpers/request.helper";
import type {LoginCommand} from "@/commands/auth.commands";


const requestHelper = useRequestHelper();

const deleteTempKeys = () => {

    // Obtém todas as chaves do localStorage
    const keys = Object.keys(localStorage);

    // Itera sobre as chaves e remove aquelas que começam com "temp"
    keys.forEach((chave) => {
        if (chave.startsWith("temp")) {
            localStorage.removeItem(chave);
        }
    });
}

const deleteUserStorages = () =>{
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userStatus');
    localStorage.removeItem('userImage');
    localStorage.removeItem('userPublicId');
    localStorage.removeItem('userTemp');
}

class AuthService {


    login(userCommand: LoginCommand) {

        return new Promise((resolve, reject) => {

            requestHelper.sendRequestPost<any>('usuario/login', userCommand,false)
                .then(ret => {

                    deleteUserStorages();
                    deleteTempKeys();


                    if (ret.data.token) {

                        localStorage.setItem('user', ret.data.token);
                        localStorage.setItem('userName', ret.data.nome);
                        localStorage.setItem('userEmail', ret.data.email);
                        localStorage.setItem('userStatus', ret.data.statusUsuario);
                        localStorage.setItem('userImage', ret.data.imagem);
                        localStorage.setItem('userPublicId', ret.data.publicId);

                        localStorage.setItem('username-login',userCommand.usuario);

                        //authStore.verificaPermissoesStore();
                    }

                    resolve(ret.data);

                })
                .catch(error => {
                    reject(error);
                });


        });

    }

    validateSession(){
        return new Promise((resolve, reject) => {

            requestHelper.sendRequestGet<any>('usuario/session')
                .then(ret => {


                    if (ret.data &&  ret.data.newToken) {


                        if(ret.data.newToken != ""){
                            localStorage.setItem('user', ret.data.newToken);
                            resolve(true);
                        }else{
                            reject(false);
                        }
                    }else{
                        reject(false);
                    }
                })
                .catch(error => {
                    reject(error);
                });


        });
    }

    logout() {
        deleteUserStorages();
        deleteTempKeys();
    }


}

export default new AuthService();